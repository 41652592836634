import axios from 'axios';
import Layout from '../shared/Layout';
import { useContext, useEffect, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import language from '../../variables/language';
import { useLocation, Link  } from 'react-router-dom';
const Tasks = () => {
  // const [params, setParams] = useState({});
  // const API_URL = process.env.REACT_APP_API_URL;
  // const moduleName = 'firmy';
  const label = 'tasks';
 // const { setTheme } = useContext(DetailsContext);
 const location = useLocation();

  return (
    <Layout>
      <header  className='header-block'>
         <div className='header-txt txt-c' >{ language[language.lang][(location.pathname.replace("/",""))] }</div>
      </header>
      <main className='main-block' >
        <div className='main-content txt-c'>tasks</div>
      </main>
    </Layout>
  )
}

export default Tasks