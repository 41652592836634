import React, { useEffect, useState } from 'react'
import PipeCard from './PipeCard'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function PipeColumn(props) {

   const [color, setColor] = useState(props.color);
   const [title, setTitle] = useState(props.title);
   const [id, setId] = useState(props.id);
   const [url, setUrl] = useState(props.url+"?stage="+id);
   const navigate = useNavigate();
   const [data,setData] =  useState([]);
   const [satatus, setSatatus] = useState('wait');


   useEffect(() => {
      if(satatus === 'none'){

      }else if(satatus === 'wait'){
       
      }else if(satatus === 'done'){
     
      }else if(satatus === 'error'){
        
      }else if(satatus === 'forbidden'){
      
      }else if(satatus === 'timeout'){
        
      }
    }, [satatus]);

    useEffect(()=>{
      setUrl(props.url+"?stage="+id);
      setId(props.id);
    },[props.url,props.id])

   useEffect(()=>{
      console.log("url"+ url);
      setSatatus('wait');
      axios.get(url, { withCredentials: true })
      .then(response => {
        setData(response.data);
        setSatatus('none');
      })
      .catch(err => {
        if(err.response.status === 401 ){
         navigate('/login');
        }
        setSatatus('error');
        console.log(err);
      });
   },[url,props.refresh])



  return (
    <div className='pipe-column'>
      <div style={{borderBottom:`3px solid ${color}`}} className='pipe-column-header'>{title}</div>
         {Array.isArray(data) ? (
               data.map(item => (
                  <PipeCard  key={item.id} stateHundler={props.stateHundler} 
                  onRefresh={props.onRefresh}
                     stages={props.stages}
                     id={item.id}
                     url={props.url}
                     color={color}
                     title={item.firstName+" "+ item.lastName} 
                     price={item.price} 
                     probability={item.probability}
                     uMod={item.uMod}
                     product={(item.product && typeof item.product === 'object')? item.product.name :"None"}
                     ></PipeCard>
               ))
            ) : (
               <div>Empty list</div>
         )}
    </div>
  )
}
