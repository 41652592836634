const language = {
    lang:'pl',
    en: {
      emailVerificationPromptText : 'The email is not correct format ! '  ,
      clients : "Clients",
      client : "Client",
      dashboard : "Dashboard",
      users : "Users",
      pipeline : "Pipeline",
      projects : "Projects",
      project : "Project",
      stage:"Stage",
      dLastContact:"Last contact",
      address:"Address",
      age:"Age",
      probability:"Probability",
      language:"Language",
      category:"Category",
      product:"Product",
      fill:"...",
      price:"Price",
      source:"Source",
      modals:{
        projects:{
          createTitle:"You can add project here",
          updateDescription: "Project update",
          updateTitle : "You can edit project here",
        },
       users:{
        createTitle:"You can add user here",
        updateDescription: "You can edit user here",
        updateTitle : "User update",
       },
       clients:{
        createTitle:"You can add client here",
        updateDescription: "You can edit client here",
        updateTitle : "Client update",
       }
      },
      tasks : "Tasks",
      settings : "Settings",
      logout : "Logout",
      logging : "Logined from",
      firstName : "First name",
      lastName : "Last name",
      email : "Email",
      tel : "Tel",
      device : "Device",
      timezone : "Time zone",
      lastActivity : "Last activity",
      placeholderSearch : "search here ...",
      save : "Save",
      dateBirth:"Data of birth",
      position:"Position",
      pl:"Polish",
      en:"English",
      icons:"",
      feedbacks:{
        done:"Successfully done !",
        error:"Something went wrong !",
        wait:"Processing...",
        timeout:"The request time of server answering is out !",
        forbidden:"Access deny!",
      },
      name:"Name",
      description:"Description",
      responsibleId:"Responsible",
      responsible:"Responsible",
    },
    pl: {
        emailVerificationPromptText : 'Nie poprawny format emailu !'  ,
        clients : "Klienci",
        client : "Klient",
        dashboard : "Dashboard",
        users : "Użytkownicy",
        stage:"Etap",
        dLastContact:"Ostatni konakt",
        address:"Adres",
        age:"Wiek",
        probability:"Wiarygodnosc",
        language:"Język",
        category:"kategoria",
        product:"Produkt",
        price:"Cena",
        source:"Źródło",
        fill:"...",
        modals:{
          projects:{
            createTitle:"Tutaj możesz dodać projekt",
            updateDescription: "Tutaj możesz zaktualizować projekt",
            updateTitle : "Edycja projektu",
          },
         users:{
          createTitle:"Tutaj możesz dodać uzytkownika",
          updateDescription: "Tutaj możesz zaktualizować uzytkownika",
          updateTitle : "Edycja uzytkownika",
         },
         clients:{
          createTitle:"Tutaj możesz dodać klienta",
          updateDescription: "Tutaj możesz zaktualizować klienta",
          updateTitle : "Edycja klienta",
         }
        },
        pipeline : "Pipeline",
        projects : "Projekty",
        project : "Projekt",
        
        tasks : "Zadania",
        settings : "Ustawienia",
        logout : "Wyloguj",
        logging : "Logowania z urądzeń",
        firstName : "Imie",
        lastName : "Nazwisko",
        email : "Email",
        tel : "Tel",
        device : "Urządzenie",
        timezone : "Strefa czasowa",
        lastActivity : "Ostatnia aktywność",
        placeholderSearch : "wyszukaj ...",
        save : "Zapisz",
        dateBirth:"Data urodzenia",
        position:"Stanowisko",
        pl:"Polski",
        en:"Angielski",
        icons:"",
        feedbacks:{
          done:"Pomyślnie wykonano !",
          error:"Wystąpil problem !",
          wait:"Przetwazanie rządania...",
          timeout:"Za długi czas oczekiwania na odpowiedż od serwera !",
          forbidden:"Brak uprawnienia !",
        },
        name:"Nazwa",
        description:"Opis",
        responsibleId:"Odpowiedzialny",
        responsible:"Odpowiedzialny",
        
    }
}
export default language;