import axios from 'axios';
import Layout from '../shared/Layout';
import { useContext, useEffect, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import language from '../../variables/language';
import { useLocation, Link  } from 'react-router-dom';
import Header from '../shared/Header';
import Form from '../shared/Form';
import ModalClient from '../klienci/ModalClient';
import PipeContainer from './PipeContainer';
import ModalPipeline from './ModalPipeline';
const Pipeline = () => {
  const label = 'clients';
  const location = useLocation();
  const url = '/api/clients';
  const [modalState ,setModalState] = useState('none');
  const [refresh, setRefresh] = useState(0);
  const [toUpdateId , setToUpdateId] = useState('');

  const [headers, setHeaders] = useState(
    {'firstName':{}, 'lastName':{}, 'email':{},'tel':{},'product':{},'stage':{},'price':{},'icons':{
      'icon-delete':{'name':'icon-delete','type' : 'icon','url':url,'value' : '','mode':'delete'},
      'icon-card':{'name':'icon-card','type' : 'icon','url':url,'value' : '','mode':'card'},
      'icon-edit':{'name':'icon-edit','type' : 'icon','url':url,'value' : '','mode':'edit'},
    }}
    );
  const [inputs, setInputs] = useState(
    {'firstName':{'name':'firstName','type' : 'text','value' : ''},
     'lastName':{'name':'lastName','type' : 'text','value' : ''},
     'email':{'name':'email','type' : 'text','value' : ''},
     'tel':{'name':'tel','type' : 'text','value' : ''},
    });
  const [selectors, setSelectors] = useState({
    });
  const [inputsToUpdate, setInputsToUpdate] = useState(
    {
      'firstName':{'name':'firstName','type' : 'text','value' : ''},
       'lastName':{'name':'lastName','type' : 'text','value' : ''},
       'email':{'name':'email','type' : 'text','value' : ''},
       'tel':{'name':'tel','type' : 'number','value' : ''},
       'price':{'name':'price','type' : 'number','value' : ''},
       'dLastContact':{'name':'dLastContact','type' : 'date','value' : ''},
      //  'probability':{'name':'probability','type' : 'number','value' : ''},
    });
  const [selectorsToUpdate, setSelectorsToUpdate] = useState({
        'responsibleId':{'name':'responsibleId','type' : 'number','url':'/api/users','value' : ''},
       // 'project':{'name':'project','type' : 'number','url':'/api/projects','value' : ''},
        'stage':{'name':'stage','type' : 'number','url':'/api/stages','value' : ''},
      //  'language':{'name':'language','type' : 'number','url':'/api/languages','value' : ''},
        // 'category':{'name':'category','type' : 'number','url':'/api/categories','value' : ''},
    });


    const stateHundler = (state,id) => {
      if(state === 'edit'){
        setModalState('edit');
        setToUpdateId(id);
      }else if(state === 'none'){
        setModalState('none');
      }else if(state === 'menu'){
        setModalState('menu');
        setToUpdateId(id);
      }
    }
    const onRefreshHundler = ()=> {
      if(refresh==="1"){
        setRefresh('0');
      }else{
        setRefresh('1');
      }
      
    }
    const stopHund = (e) => {
      e.stopPropagation();
      console.log("sdsd");
    }

  return (
    <Layout>
    <Header name={language[language.lang][(location.pathname.replace("/",""))]}   opened='300px' >
      <Form inputs={inputs} selectors={selectors} url={url} onRefreshHundler={onRefreshHundler} name={language[language.lang].modals[label].createTitle}></Form>
    </Header>
    <main className='main-block'  >
      <div className='main-content txt-c'  >
        <PipeContainer stateHundler={stateHundler} url='/api/stages' refresh={refresh} onRefreshHundler={onRefreshHundler}/>
      {/* <ActivityList name='' label={label} url={url} done={refresh} stateHundler={stateHundler} onRefreshHundler={onRefreshHundler} headers={headers}/> */}
      </div>  
      <ModalClient modalState={modalState} inputs={inputsToUpdate} selectors={selectorsToUpdate} url={url} toUpdate={url+'/'+ toUpdateId }  onRefreshHundler={onRefreshHundler} stateHundler={stateHundler} name={language[language.lang].modals[label].updateDescription} title={language[language.lang].modals[label].updateTitle} />
      <ModalPipeline modalState={modalState} inputs={inputsToUpdate} selectors={selectorsToUpdate} url={url} toUpdate={url+'/'+ toUpdateId } id={toUpdateId}  onRefreshHundler={onRefreshHundler} stateHundler={stateHundler} name={language[language.lang].modals[label].updateDescription} title={language[language.lang].modals[label].updateTitle} />

    </main>

  </Layout>
  )
}

export default Pipeline