import React, { useContext,useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../providers/AuthContext';
import axios from 'axios';

const Logout = () => {
  const [timeZon,setTimeZon] = useState('');
const navigate = useNavigate();
const { setIsLoggedIn } = useContext(AuthContext);
const API_URL = process.env.REACT_APP_API_URL;

useEffect(() => {
  axios.post('/api/logout', null, {
      withCredentials: true ,
  })
  .then(response => {
      setIsLoggedIn(false);
      navigate('/login');
  }).catch(err => {
      setIsLoggedIn(false);
      navigate('/login');
      console.log(err);
  });
}, [API_URL, navigate, setIsLoggedIn]);

  return (
    <></>
  )
}

export default Logout