import React, { useState } from 'react'
import {  useNavigate } from 'react-router-dom';
import Icon from '../../components/tables/Icon';
import axios from 'axios';

export default function StagesMenu(props) {
   const navigate = useNavigate(); 
   const [display , setDisplay ] = useState('block');
   const [displayStages , setDisplayStages ] = useState('none');
   const [displayCard , setDisplayStagesCard ] = useState('block');
   const [fixed , setFixed ] = useState('static');



   const oncliHundler =(e)=> {
   
      if(display=='none'){
        // setDisplay('block');
        // setDisplayStages('none');
         
      }else{
       //  setDisplay('none');
         props.stateHundler('none',props.id);
      }
   }
   const changeStage = (e) => {
      e.stopPropagation();
      if(displayStages==='none'){
         setDisplayStages('flex');
      }else{
         setDisplayStages('none');
      }
     
   }
   

   const setStage = (e,id) => {
      e.stopPropagation();
      axios.put('/api/clients/'+props.id,
         JSON.stringify({stage:id}), {
            withCredentials: true,
            headers: {
               'Content-Type': 'application/json',
            },
      }).then(response => {
        props.onRefresh();
        props.stateHundler('none',props.id);
      })
      .catch(err => {
         navigate('/pipeline');
      
      console.log(err);
      });
   }
   const goToClient = (e) => {
      e.stopPropagation();
      navigate("/clients/"+props.id);
   }
   const onRefreshHundler = ()=>{

   }
  return (
    <div className='pipe-menu' onClick={oncliHundler}   >
      
      <div className='menu-btn-goto' onClick={goToClient} ></div>

      <div className='menu-pipe-selector' >
         {Array.isArray(props.stages) ? (
                  props.stages.map(item => (
                     <div key={item.id+'sd'} onClick={ (e) => setStage(e, item.id) } style={{borderLeft:`3px solid ${item.color}`,boxShadow:` 0px 0px 3px ${item.color}`}} className='card-selector-stage txt-c' >{item.name}</div>
                  ))
               ) : (
                  <div>Empty list</div>
            )}
      </div>
    </div>
  )
}
