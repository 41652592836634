import React, { useEffect, useState } from 'react'
import PipeColumn from './PipeColumn'
import axios from 'axios';
import '../../css/pipeline.css'
import {  useNavigate } from 'react-router-dom';
export default function PipeContainer(props) {

   const navigate = useNavigate();
   const [data,setData] =  useState([]);
   const [satatus, setSatatus] = useState('wait');
   const [refresh, setRefresh] = useState('0');


   useEffect(() => {
      if(satatus === 'none'){

      }else if(satatus === 'wait'){
       
      }else if(satatus === 'done'){
     
      }else if(satatus === 'error'){
        
      }else if(satatus === 'forbidden'){
      
      }else if(satatus === 'timeout'){
        
      }
    }, [satatus]);

    const onRefresh = () => {
      console.log('asdasd-------------');
      if(refresh!=='1'){
        console.log('asdasd true');
        setRefresh('1');
      }else{
        console.log('asdasd false');
        setRefresh('0');
      }
    }

    useEffect(()=>{

      onRefresh();
    },[props.refresh])
   useEffect(()=>{
    console.log('get stages');
      axios.get(props.url, { withCredentials: true })
      .then(response => {
        setData([]);
        console.log('stages response piplen')
        setData(response.data);
        setSatatus('none');
      })
      .catch(err => {
      //   if(err.response.status === 401 ){
      //    navigate('/login');
      //   }
        setSatatus('error');
        console.log(err);
      });
   },[props.url,refresh])

  return (
    <div className='pipe-container'>
      {Array.isArray(data) ? (
               data.map(item => (

                  <PipeColumn stages={data} stateHundler={props.stateHundler}  key={item.id} id={item.id} title={item.name} color={item.color} onRefresh={onRefresh} refresh={refresh} url='/api/clients'></PipeColumn>

               ))
            ) : (
               <div>Empty list</div>
         )}
    </div>
  )
}
