import axios from 'axios';
import Layout from '../shared/Layout';
import { useContext, useEffect, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import ActivityList from '../../components/settings/ActivityList';
import language from '../../variables/language';
import { useLocation, Link  } from 'react-router-dom';
const Settings = () => {
  const [modalState ,setModalState] = useState('none');
  const [refresh, setRefresh] = useState(0);
  const [headers, setHeaders] = useState({'device':{}, 'timezone':{}, 'lastActivity':{},'icons':{
    'icon-delete':{'name':'icon-delete','type' : 'icon','url':'/api/sessions','value' : '','mode':'delete'},
  }});
  // const [params, setParams] = useState({});
  // const API_URL = process.env.REACT_APP_API_URL;
  // const moduleName = 'firmy';
  const label = 'settings';
 // const { setTheme } = useContext(DetailsContext);
 const location = useLocation();

 const stateHundler = (state,id) => {
  if(state === 'edit'){
    setModalState('edit');
   // setToUpdateId(id);
  }else if(state === 'none'){
    setModalState('none');
  }
}
const onRefreshHundler = ()=> {
  if(refresh==="1"){
    setRefresh('0');
  }else{
    setRefresh('1');
  }
  
}

  return (
    <Layout>
      <header  className='header-block'>
         <div className='header-txt txt-c' >{ language[language.lang][(location.pathname.replace("/",""))] }</div>
      </header>
      <main className='main-block' >
        <div className='main-content txt-c'>
          <ActivityList name={language[language.lang].logging} url='/api/sessions'  done={refresh} stateHundler={stateHundler} onRefreshHundler={onRefreshHundler} headers={headers} />
        </div>
      </main>

    </Layout>
  )
}

export default Settings