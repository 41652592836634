import React from 'react';

const WaitingIcon = (props) => {
    const { styles } = props;

    return (

        <svg className="svglogo" width="150" height="150" style={styles.parenel} xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="waiting-icon"  x1="0%" y1="0%" x2="100%" y2="0%" style={styles.gradient}>
                    <stop offset="0%"  />
                    <stop offset="100%" />
                </linearGradient>
            </defs>
            <g transform="translate(0.000000,150.000000) scale(0.050000,-0.050000)"
               fill="url(#waiting-icon)" stroke="none">
                <path d="M746 2302 c3 -42 9 -88 14 -102 5 -14 46 -245 91 -515 87 -523 91
                      -534 142 -521 14 3 27 15 30 28 4 12 -32 249 -80 526 -47 276 -83 506 -79 509
                      3 3 77 -19 163 -49 87 -31 275 -97 418 -148 143 -50 341 -120 440 -155 99 -35
                      193 -68 209 -74 23 -9 27 -9 23 3 -3 8 -11 33 -18 56 -9 29 -20 45 -38 52 -32
                      12 -246 88 -486 173 -99 35 -245 86 -325 115 -80 29 -163 58 -185 65 -22 7
                      -85 30 -140 50 -55 21 -119 43 -142 51 l-43 13 6 -77z" fill="url(#waiting-icon)"/>
                <path  d="M737 1682 l-38 -39 101 -618 c55 -340 101 -620 103 -620 7 -6 864
                      710 875 730 11 22 10 27 -8 45 -15 15 -27 19 -43 14 -12 -4 -186 -142 -386
                      -306 -200 -165 -367 -296 -371 -291 -4 4 -46 251 -94 548 -48 297 -90 548 -94
                      558 -6 16 -12 13 -45 -21z" fill="url(#waiting-icon)"/>
                <path d="M1880 1093 c-305 -256 -556 -468 -558 -472 -1 -4 17 -16 41 -28 l44
                      -21 570 476 570 477 -46 17 c-25 9 -50 17 -56 17 -5 0 -260 -210 -565 -466z" fill="url(#waiting-icon)"/>
            </g>
        </svg>
    );
};

export default WaitingIcon;