
import  { useState ,useEffect} from 'react'
import language from '../../variables/language';
import GradientShape from '../../components/GradientShape';
import WaitingIcon from './WaitingIcon';
export default function Feedback(props) {
   const [status, setStatus] = useState('none');
   const [waiting, setWaiting] = useState('none');
   const [done, setDone] = useState('none');
   const [error, setError] = useState('none');
   const [timeout, setTimeout] = useState('none');
   const [forbidden, setForbidden] = useState('none');
   const okHundler =() => {
      props.statusHundler('none');
   }
   useEffect(() => {
      if(props.status === 'none'){
         setWaiting('none');
         setDone('none');
         setError('none');
         setTimeout('none');
         setForbidden('none');
      }else if(props.status === 'wait'){
         setWaiting('flex');
         setDone('none');
         setError('none');
         setTimeout('none');
         setForbidden('none');
      }else if(props.status === 'done'){
         setWaiting('none');
         setDone('flex');
         setError('none');
         setTimeout('none');
         setForbidden('none');
      }
      else if(props.status === 'error'){
         setWaiting('none');
         setDone('none');
         setError('flex');
         setTimeout('none');
         setForbidden('none');
      }else if(props.status === 'timeout'){
         setWaiting('none');
         setDone('none');
         setError('none');
         setTimeout('flex');
         setForbidden('none');
      }else if(props.status === 'forbidden'){
         setWaiting('none');
         setDone('none');
         setError('none');
         setTimeout('none');
         setForbidden('flex');
      }
    }, [props.status]);
    const styles_logo = {
      parenel: {  stroke: 'black',display:waiting, strokeWidth: 1, marginInline: 'auto',  filter:"blur(0px)",marginBlock:`auto` ,zIndex:5,},
      gradient: {  },
      colorOne: { stopColor:`rgba(101, 100, 100, 0.32)`},
      colorTwo: { stopColor:`#0bc6d9` },
  };


  return (
    <div>
      <div className='form-feedback-icon' style={{display:done}}>
         <svg xmlns="http://www.w3.org/2000/svg" className='img-form'  viewBox="0 -960 960 960" >
            <path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" fill="green"/>
            
         </svg>
         <p className='txt-c'>{language[language.lang].feedbacks.done}</p>
         <button className='form-feedback-button txt-c' onClick={okHundler}>OK</button>
      </div>
      <div className='form-feedback-icon' style={{display:error}}>
         <svg xmlns="http://www.w3.org/2000/svg"  className='img-form' viewBox="0 -960 960 960">
            <path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240ZM330-120 120-330v-300l210-210h300l210 210v300L630-120H330Zm34-80h232l164-164v-232L596-760H364L200-596v232l164 164Zm116-280Z" fill="orange"/>
            
         </svg>
         <p className='txt-c'>{language[language.lang].feedbacks.error}</p>
         <button className='form-feedback-button txt-c' onClick={okHundler}>OK</button>
      </div>
      <div className='form-feedback-icon' style={{display:timeout}}>
         <svg xmlns="http://www.w3.org/2000/svg"  className='img-form' viewBox="0 -960 960 960">
         <path d="M320-160h320v-120q0-66-47-113t-113-47q-66 0-113 47t-47 113v120Zm160-360q66 0 113-47t47-113v-120H320v120q0 66 47 113t113 47ZM160-80v-80h80v-120q0-61 28.5-114.5T348-480q-51-32-79.5-85.5T240-680v-120h-80v-80h640v80h-80v120q0 61-28.5 114.5T612-480q51 32 79.5 85.5T720-280v120h80v80H160Z" fill="gray"/>
            
         </svg>
         <p className='txt-c'>{language[language.lang].feedbacks.timeout}</p>
         <button className='form-feedback-button txt-c' onClick={okHundler}>OK</button>
      </div>
      <div className='form-feedback-icon' style={{display:forbidden}}>
         <svg xmlns="http://www.w3.org/2000/svg"  className='img-form' viewBox="0 -960 960 960">
         <path d="M280-240q-100 0-170-70T40-480q0-100 70-170t170-70q66 0 121 33t87 87h432v240h-80v120H600v-120H488q-32 54-87 87t-121 33Zm0-80q66 0 106-40.5t48-79.5h246v120h80v-120h80v-80H434q-8-39-48-79.5T280-640q-66 0-113 47t-47 113q0 66 47 113t113 47Zm0-80q33 0 56.5-23.5T360-480q0-33-23.5-56.5T280-560q-33 0-56.5 23.5T200-480q0 33 23.5 56.5T280-400Zm0-80Z" fill='orange'/>
            
         </svg>
         <p className='txt-c' >{language[language.lang].feedbacks.forbidden}</p>
         <button className='form-feedback-button txt-c' onClick={okHundler}>OK</button>
      </div>
      <div className='form-feedback-icon' style={{display:waiting}}>
         <WaitingIcon styles={styles_logo} />
         <p className='txt-c'>{language[language.lang].feedbacks.wait}</p>
      </div>
      
      
    </div>
  )
}
