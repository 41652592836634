import React from 'react'
import { useContext, useEffect, useState } from 'react';
import '../../css/search.css'
import language from '../../variables/language';

export default function Search(props) {
   const [endpoint, setEndpoint] = useState(props.endpoint);

   const searchHundler =() => {

   };

  return (
    <div className='header-search-container'>
      <div className='header-search-buttons-container'>
         <button className='header-search-button' hidden onClick={searchHundler}>
            <svg xmlns="http://www.w3.org/2000/svg" className='search-icon'  viewBox="0 -960 960 960"><path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"/>
            </svg>
         </button>
         <button className='header-search-button'>
            <svg xmlns="http://www.w3.org/2000/svg"  className='search-icon'  viewBox="0 -960 960 960"><path d="M440-120v-240h80v80h320v80H520v80h-80Zm-320-80v-80h240v80H120Zm160-160v-80H120v-80h160v-80h80v240h-80Zm160-80v-80h400v80H440Zm160-160v-240h80v80h160v80H680v80h-80Zm-480-80v-80h400v80H120Z"/>
            </svg>
         </button>
      </div>
      <input type="text" className='header-search-input' placeholder={language[language.lang].placeholderSearch} />

    </div>
  )
}
