import React, { useState } from 'react'
import {  useNavigate } from 'react-router-dom';
import Icon from '../../components/tables/Icon';
import axios from 'axios';

export default function PipeCard(props) {
   const navigate = useNavigate(); 
   const [display , setDisplay ] = useState('block');
   const [displayStages , setDisplayStages ] = useState('none');
   const [displayCard , setDisplayStagesCard ] = useState('block');
   const [fixed , setFixed ] = useState('static');



   const oncliHundler =(e)=> {
   
      if(display=='none'){
        // setDisplay('block');
        // setDisplayStages('none');
         
      }else{
       //  setDisplay('none');
         props.stateHundler('menu',props.id);
      }
   }
   const changeStage = (e) => {
      e.stopPropagation();
      if(displayStages==='none'){
         setDisplayStages('flex');
      }else{
         setDisplayStages('none');
      }
     
   }
   

   const setStage = (e,id) => {
      e.stopPropagation();
      setDisplayStages('none');
      
      axios.put('/api/clients/'+props.id,
         JSON.stringify({stage:id}), {
            withCredentials: true,
            headers: {
               'Content-Type': 'application/json',
            },
      }).then(response => {
        props.onRefresh();
        setDisplayStagesCard('none');
      })
      .catch(err => {
         navigate('/pipeline');
      if(err.response.status === 401 ){
         navigate('/login');
      }
      console.log(err);
      });
   }
   const goToClient = (e) => {
      e.stopPropagation();
      navigate("/clients/"+props.id);
   }
   const onRefreshHundler = ()=>{

   }
  return (
    <div className='pipe-card' onClick={oncliHundler}  style={{display:displayCard, borderLeft:`0px solid ${props.color}`,borderRight:`0px solid ${props.color}`}} >
      <div style={{display:'block'}}>
         <h4 className='pipe-card-title' >{props.title}</h4>
         <p  className='pipe-card-price'>{props.price} zł</p>
         <div className='pipe-card-line'>
            <div className='pipe-card-line-inner'  style={{ width:`${props.probability}%` }}></div>
         </div>
         <p  className='pipe-card-prod'>{props.product}</p>
      </div>
      <div className='card-icons' style={{display: (display==='none')? 'flex':'none'}}>
         <div className='card-btn-goto' onClick={goToClient} ></div>
         <div className='card-btn-stage' onClick={changeStage} ></div>
      </div>
      {/* <div className='card-pipe-selector' style={{display:displayStages}}>
         {Array.isArray(props.stages) ? (
                  props.stages.map(item => (
                     <div key={item.id+'sd'} onClick={ (e) => setStage(e, item.id) } style={{borderLeft:`3px solid ${item.color}`,boxShadow:` 0px 0px 3px ${item.color}`}} className='card-selector-stage txt-c' >{item.name}</div>
                  ))
               ) : (
                  <div>Empty list</div>
            )}
      </div> */}
    </div>
  )
}
