import { useEffect, useState } from 'react';
import '../../css/modals.css';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';
import PipeCard from './PipeCard';
import StagesMenu from './StagesMenu';
export default function ModalPipeline(props) {
   const navigate = useNavigate();
   const [visibility, setVisibility] = useState('none');
   const [inputs, setInputs] = useState({...props.inputs });
   const [data,setData] =  useState([]);
   const [satatus, setSatatus] = useState('wait');
   const modalHideHundler = () => {
      setVisibility('none');
      props.stateHundler('none');
   }
 
   useEffect(()=>{
      if(props.modalState === 'menu'){
        
         setVisibility('block');
      }else{
         setVisibility('none');
      }
      
   },[props.modalState]);
  

   useEffect(()=>{
      console.log('get stages');
        axios.get('/api/stages', { withCredentials: true })
        .then(response => {
          setData([]);
          console.log('stages response piplen')
          setData(response.data);
          setSatatus('none');
        })
        .catch(err => {
        //   if(err.response.status === 401 ){
        //    navigate('/login');
        //   }
          setSatatus('error');
          console.log(err);
        });
     },[props.modalState])


  return (
    <div className='modal-menu' onClick={modalHideHundler} style={{display:visibility}} >
       <StagesMenu  
       id={props.id}
       stateHundler={props.stateHundler} 
                  onRefresh={props.onRefreshHundler}
                     stages={data}
                     ></StagesMenu>
      {/* <ClientWindow inputs={inputs} selectors={props.selectors} url={props.toUpdate}   onRefreshHundler={props.onRefreshHundler}  name={props.name} title={props.title} /> */}
    </div>
  )
}
