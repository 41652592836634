import { useEffect, useState } from 'react';
import '../../css/modals.css';
import ModalWindow from './ModalWindow';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';
export default function ModalUpdate(props) {
   const navigate = useNavigate();
   const [visibility, setVisibility] = useState('none');
   const [inputs, setInputs] = useState({...props.inputs });
   const [data, setData] = useState([]);
   const modalHideHundler = () => {
      setVisibility('none');
      props.stateHundler('none');
   }
 
   useEffect(()=>{
      if(props.modalState === 'edit'){
         axios.get(props.toUpdate, { withCredentials: true })
            .then(response => {
               setData(response.data);
               console.log("modal update data +>" +JSON.stringify(data, null, 2));
               
            })
            .catch(err => {
               if(err.response.status === 401 ){
                  console.log(err);
                  navigate('/login');
                }else{
                  console.log(err);
                }
            });
         setVisibility('block');
      }
      
   },[props.modalState]);
   useEffect(()=>{
      setInputs({...props.inputs });
   },[props.inputs])
  return (
    <div className='modal-update' onClick={modalHideHundler} style={{display:visibility}} >
      <ModalWindow inputs={inputs} selectors={props.selectors} url={props.toUpdate} data={data} onRefreshHundler={props.onRefreshHundler}  name={props.name} title={props.title} />
    </div>
  )
}
