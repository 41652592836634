import React from 'react'

export default function NavPersonal(props) {
  return (
   <div className='nav-pers-container'>
      <img className='nav-img-personal' src={props.path} alt={props.name} />
      <div className='nav-txt-personal'>{props.firstName} {props.lastName}</div>
   </div>
  )
}
