import { useEffect, useState } from 'react';
import '../../css/modals.css';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';
import ClientWindow from './ClientWindow';
export default function ModalClient(props) {
   const navigate = useNavigate();
   const [visibility, setVisibility] = useState('none');
   const [inputs, setInputs] = useState({...props.inputs });
  
   const modalHideHundler = () => {
      setVisibility('none');
      props.stateHundler('none');
   }
 
   useEffect(()=>{
      if(props.modalState === 'edit'){
        
         setVisibility('block');
      }
      
   },[props.modalState]);
   useEffect(()=>{
      setInputs({...props.inputs });
   },[props.inputs])
  
  return (
    <div className='modal-update' onClick={modalHideHundler} style={{display:visibility}} >
      <ClientWindow inputs={inputs} selectors={props.selectors} url={props.toUpdate}   onRefreshHundler={props.onRefreshHundler}  name={props.name} title={props.title} />
    </div>
  )
}
