import axios from 'axios';
import Layout from '../shared/Layout';
import { useContext, useEffect, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import language from '../../variables/language';
import { useLocation, Link ,useParams,useSearchParams } from 'react-router-dom';
import Header from '../shared/Header';
import FormClient from './FormClient';
import {  useNavigate } from 'react-router-dom';
const Card = () => {
  const location = useLocation();
  const label = 'clients';
  const { id } = useParams();
    const url = '/api/clients/'+id;
    const [searchParams] = useSearchParams();
    const [modalState ,setModalState] = useState('none');
    const [refresh, setRefresh] = useState(0);
    const [toUpdateId , setToUpdateId] = useState('');
    const [mode, setMode] = useState('edit');
    const [statusform, setStatusForm] = useState('none');
    const [data, setData] = useState([]);
    const navigate = useNavigate();
  
 
    const [inputs, setInputs] = useState(
      {'firstName':{'name':'firstName','type' : 'text','value' : ''},
         'lastName':{'name':'lastName','type' : 'text','value' : ''},
         'email':{'name':'email','type' : 'text','value' : ''},
         'tel':{'name':'tel','type' : 'number','value' : ''},
         'dLastContact':{'name':'dLastContact','type' : 'date','value' : ''},
         'probability':{'name':'probability','type' : 'number','value' : ''},
         'price':{'name':'price','type' : 'number','value' : ''},
      });
    const [selectors, setSelectors] = useState({
          'responsibleId':{'name':'responsibleId','type' : 'number','url':'/api/users','value' : ''},
         'project':{'name':'project','type' : 'number','url':'/api/projects','value' : ''},
          'stage':{'name':'stage','type' : 'number','url':'/api/stages','value' : ''},
         'language':{'name':'language','type' : 'number','url':'/api/languages','value' : ''},
          'category':{'name':'category','type' : 'number','url':'/api/categories','value' : ''},
          'product':{'name':'product','type' : 'number','url':'/api/products','value' : ''},
          'source':{'name':'source','type' : 'number','url':'/api/sources','value' : ''},
      });


      const stateHundler = (state,id) => {
        if(state === 'edit'){
          setModalState('edit');
          // console.log("ID:"+id);
          setToUpdateId(id);
        }else if(state === 'none'){
          setModalState('none');
        }
      }
      const onRefreshHundler = ()=> {
        if(refresh==="1"){
          setRefresh('0');
        }else{
          setRefresh('1');
        }
        
      }

      
   useEffect(()=>{
    axios.get(url, { withCredentials: true })
    .then(response => {
       setData(response.data);
       // console.log("modal update data +>" +JSON.stringify(data, null, 2));
       setStatusForm('none');
    })
    .catch(err => {
       setStatusForm('error');
       if(err.response.status === 401 ){
          // console.log(err);
          navigate('/login');
        }else{
          // console.log(err);
        }
    });
 },[url,refresh])


 useEffect(()=>{
    const updatedInputs = { ...inputs }
    Object.keys(inputs).forEach(fieldName => {
       updatedInputs[fieldName].value = data[fieldName]+'';
    //   // console.log(inputs[fieldName].name+ '<- is )(inmodal window)()()->' + updatedInputs[fieldName].value);
     });
     const updatedSelectors = { ...selectors }
   //  // console.log('selectora obj :'+ JSON.stringify(selectors, null, 2));
     Object.keys(selectors).forEach(fieldName => {
       if (updatedSelectors.hasOwnProperty(fieldName)) {
          // Jeśli pole już istnieje, aktualizujemy jego wartość
          // console.log("selectors> name: "+fieldName+' | data: '+JSON.stringify(data[fieldName], null, 2));
           var id = data[fieldName] && typeof data[fieldName] === 'object' && data[fieldName]['id'] ? data[fieldName]['id'] :data[fieldName];
          updatedSelectors[fieldName].value = id;
      } else {
          var id = data[fieldName] && typeof data[fieldName] === 'object' && data[fieldName]['id'] ? data[fieldName]['id'] : data[fieldName];
          // Jeśli pole nie istnieje, dodajemy nowe pole do obiektu inputs
          updatedSelectors[fieldName] = {
              'value': id
          };
       }
     //  // console.log('selectors all ---------------- obj :'+ JSON.stringify(selectors, null, 2));
     //  // console.log(inputs[fieldName].name+ '<- is ->' + formData[fieldName]);
     });
     setSelectors(updatedSelectors);
     setInputs(updatedInputs);
     // console.log('selectors afte  ><><><><><><><><><><><:'+ JSON.stringify(selectors, null, 2));
     
 },[data]);

  return (
    <Layout>
    {/* <Header name={language[language.lang]['client']}   opened='300px' >
     <div></div>
    </Header> */}
    <header style={{height:15}} ></header>
    <main className='main-block' >
      <div className='main-content txt-c' >
        <FormClient clasWrapper='wide-input-wraper' clasContainer='wide-container'  clasInputs='wide-input' clasSelectors='wide-selection' inputs={inputs} selectors={selectors} status={statusform} url={url} mode={mode} data={data} name='ssd' onRefreshHundler={onRefreshHundler} ></FormClient>
      </div>  

    </main>

  </Layout>
  )
}

export default Card;