import React from 'react';
import '../../css/table.css';
import language from '../../variables/language';
import Icon from './Icon';
 
 const Tr = ({ headers, item ,btr, td , onRefreshHundler, stateHundler,label}) => {
   return (
     <tr  className={btr} >
       {Object.keys(headers).map(header => (
         <td className={td} key={header}>
               <div className='txt-c-secon table-buttons-container' data-label={language[language.lang][header]} >
                { item[header] && typeof item[header] === 'object' && item[header].name ? item[header].name : item[header]}
                {Object.keys(headers[header]).map(icon => (
                  <Icon label={label} key={headers[header][icon].name} mode={headers[header][icon].mode} stateHundler={stateHundler}  className={headers[header][icon].name} url={headers[header][icon].url +'/'+ item['id']} id={ item['id']} data-label=''onRefreshHundler={onRefreshHundler} /> ))}
                </div>
               

         </td>
       ))}
       
     </tr>
   );
 };
 export default Tr;