import React from 'react';
import Tr from './Tr';
import '../../css/table.css';
import language from '../../variables/language';

 const Table = ({ headers, data ,table, thead, tbody, htr, btr, th, td, onRefreshHundler , stateHundler,label}) => {
   return (
     <table border="1" className={table}>
       <thead  className={thead} >
         <tr  className={htr}>
           {Object.keys(headers).map(header => (
             <th  className={th} key={header}>{language[language.lang][header]}</th>
           ))}
         </tr>
       </thead>
       <tbody className={tbody} >
       {Array.isArray(data) ? (
          data.map((item, index) => (
            <Tr btr={btr} td={td} key={index} label={label} onRefreshHundler={onRefreshHundler} stateHundler={stateHundler} headers={headers} item={item} />
          ))
        ) : (
          <tr>
            <td colSpan="..." className="info-mssg">Brak danych do wyświetlenia</td>
          </tr>
        )}
         {/* {data.map((item, index) => (
           <Tr btr={btr} td={td} key={index} label={label}  onRefreshHundler={onRefreshHundler} stateHundler={stateHundler} headers={headers} item={item} />
         ))} */}
       </tbody>
     </table>
   );
 };
 export default Table;
 