import React from 'react'

export default function NavBtn({name,path,children,location}) {
  return (
    <div className={'nav-btn-item' +" "+ location} >
      {children}
      <div className='nav-btn-item-text'>{name}</div>

    </div>
  )
}
