import axios from 'axios';
import Layout from '../shared/Layout';
import { useContext, useEffect, useState } from 'react';
import DetailsContext from '../../providers/DetailsContext';
import language from '../../variables/language';
import { useLocation, Link  } from 'react-router-dom';
import Header from '../shared/Header';
import Form from '../shared/Form';
import ActivityList from '../../components/settings/ActivityList';
import ModalUpdate from '../shared/ModalUpdate';
const Projects = () => {
    const label = 'projects';
    const location = useLocation();
    const [modalState ,setModalState] = useState('none');
    const [refresh, setRefresh] = useState(0);
    const [toUpdateId , setToUpdateId] = useState('');

    const [headers, setHeaders] = useState(
      {'name':{}, 'description':{},'responsible':{},'icons':{
        'icon-delete':{'name':'icon-delete','type' : 'icon','url':'/api/users','value' : '','mode':'delete'},
        'icon-edit':{'name':'icon-edit','type' : 'icon','url':'/api/users','value' : '','mode':'edit'},
      }}
      );
    const [inputs, setInputs] = useState(
      {'name':{'name':'name','type' : 'text','value' : ''},
       'description':{'name':'description','type' : 'text','value' : ''},
      });
    const [selectors, setSelectors] = useState({
        'responsibleId':{'name':'responsibleId','type' : 'number','url':'/api/users','value' : ''},
      });
    const [inputsToUpdate, setInputsToUpdate] = useState(
      {'name':{'name':'name','type' : 'text','value' : ''},
         'description':{'name':'description','type' : 'text','value' : ''},
      });
    const [selectorsToUpdate, setSelectorsToUpdate] = useState({
          'responsibleId':{'name':'responsibleId','type' : 'number','url':'/api/users','value' : ''},
      });


      const stateHundler = (state,id) => {
        if(state === 'edit'){
          setModalState('edit');
          console.log("ID:"+id);
          setToUpdateId(id);
        }else if(state === 'none'){
          setModalState('none');
        }
      }
      const onRefreshHundler = ()=> {
        if(refresh==="1"){
          setRefresh('0');
        }else{
          setRefresh('1');
        }
        
      }


  return (
    <Layout>
      <Header name={language[language.lang][(location.pathname.replace("/",""))]}   opened='300px' >
        <Form inputs={inputs} selectors={selectors} url='/api/projects' onRefreshHundler={onRefreshHundler} name='Tutaj możesz dodać nowy project'></Form>
      </Header>
      <main className='main-block' >
        <div className='main-content txt-c' >
        <ActivityList name='' url='/api/projects' done={refresh} stateHundler={stateHundler} onRefreshHundler={onRefreshHundler} headers={headers}/>
        </div>  
        <ModalUpdate modalState={modalState} inputs={inputsToUpdate} selectors={selectorsToUpdate} url='/api/projects' toUpdate={'/api/projects/'+ toUpdateId }  onRefreshHundler={onRefreshHundler} stateHundler={stateHundler} name={language[language.lang].modals[label].updateDescription} title={language[language.lang].modals[label].updateTitle} />
      </main>

    </Layout>
  )
}

export default Projects;