import React, { useEffect, useState } from 'react';
import FormClient from './FormClient';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';

export default function ClientWindow(props) {
   const [inputs, setInputs] = useState(props.inputs);
   const navigate = useNavigate();
   const [selectors, setSelectors] = useState(props.selectors);
   const [mode, setMode] = useState('edit');
   const [statusform, setStatusForm] = useState(props.status);
   const [data, setData] = useState([]);
   const stopProp = (event) => {
      event.stopPropagation();
   }
  


   useEffect(()=>{
      axios.get(props.url, { withCredentials: true })
      .then(response => {
         setData(response.data);
         // console.log("modal update data +>" +JSON.stringify(data, null, 2));
         setStatusForm('none');
      })
      .catch(err => {
         setStatusForm('error');
         if(err.response.status === 401 ){
            // console.log(err);
            navigate('/login');
          }else{
            // console.log(err);
          }
      });
   },[props.url,statusform,props.onRefreshHundler])


   useEffect(()=>{
      const updatedInputs = { ...inputs }
      Object.keys(inputs).forEach(fieldName => {
         updatedInputs[fieldName].value = data[fieldName]+'';
      //   // console.log(inputs[fieldName].name+ '<- is )(inmodal window)()()->' + updatedInputs[fieldName].value);
       });
       const updatedSelectors = { ...selectors }
     //  // console.log('selectora obj :'+ JSON.stringify(selectors, null, 2));
       Object.keys(selectors).forEach(fieldName => {
         if (updatedSelectors.hasOwnProperty(fieldName)) {
            // Jeśli pole już istnieje, aktualizujemy jego wartość
            // console.log("selectors> name: "+fieldName+' | data: '+JSON.stringify(data[fieldName], null, 2));
             var id = data[fieldName] && typeof data[fieldName] === 'object' && data[fieldName]['id'] ? data[fieldName]['id'] :data[fieldName];
            updatedSelectors[fieldName].value = id;
        } else {
            var id = data[fieldName] && typeof data[fieldName] === 'object' && data[fieldName]['id'] ? data[fieldName]['id'] : data[fieldName];
            // Jeśli pole nie istnieje, dodajemy nowe pole do obiektu inputs
            updatedSelectors[fieldName] = {
                'value': id
            };
         }
       //  // console.log('selectors all ---------------- obj :'+ JSON.stringify(selectors, null, 2));
       //  // console.log(inputs[fieldName].name+ '<- is ->' + formData[fieldName]);
       });
       setSelectors(updatedSelectors);
       setInputs(updatedInputs);
       // console.log('selectors afte  ><><><><><><><><><><><:'+ JSON.stringify(selectors, null, 2));
       
   },[data,props.status]);
  
  return (
   <div className='modal-window' onClick={stopProp}>
      <h3 className='modal-w-title txt-c' >{props.title}</h3>

      <FormClient clasWrapper='clientform-input-wraper' clasContainer='clientform-container' clasInputs='clientform-input' clasSelectors='clientform-selection' inputs={inputs} selectors={selectors} status={statusform} url={props.url} mode={mode} data={data} name={props.name} onRefreshHundler={props.onRefreshHundler} ></FormClient>
   </div>
  )
}
