import React from 'react'

export default function NavBar({className ,children }) {
  return (
    <nav className={className} >
      <div>
        {children }
      </div>
    </nav>
  )
}
